import Button from '@/components/Button';

import React, { useEffect, useState } from 'react';
import { IconBrandGithub } from '@tabler/icons-react';
import { Octokit } from 'octokit';

export const getStarCount = async () => {
  const octokit = new Octokit();
  const res = await octokit.request('GET /repos/{owner}/{repo}', {
    owner: 'artilleryio',
    repo: 'artillery',
  });
  return res.data.stargazers_count;
};

export default function GitHubStars() {
  const [starsCount, setStarsCount] = useState(8000);

  useEffect(() => {
    getStarCount().then(setStarsCount);
  }, []);

  const formattedStarsCount = starsCount.toLocaleString('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: 1,
  });

  return (
    <Button href="https://github.com/artilleryio/artillery" variant="secondary">
      <IconBrandGithub size={16} stroke={1.5} className="-ml-0.5" />
      {formattedStarsCount}
    </Button>
  );
}
